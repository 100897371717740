import { useTranslation } from "react-i18next";

const Footer = ({ iconUrl = 'https://his.amihealthcare.co/account/register', description1, description2 }) => {
    const {t:trans} = useTranslation("common");
    return (
        <section className="footer">
            <div className="nice-container">
                <div className="nice-row justify-center py-4">
                    <div className="nice-col-xl-3 nice-col-lg-3 nice-col-sm-6 my-2">
                        <h3 className="text-4 text-white font-bold uppercase">America</h3>
                        <ul>
                            <li><p className="text-white  my-2 footer-item">{description1}</p></li>
                        </ul>
                    </div>
                    <div className="nice-col-xl-3 nice-col-lg-3 nice-col-sm-6 my-2">
                        <h3 className="text-4 text-white font-bold uppercase">Asia</h3>
                        <ul>
                            <li><p className="text-white my-2 footer-item">{description2}</p></li>
                        </ul>
                    </div>
                    <div className="nice-col-xl-3 nice-col-lg-3 nice-col-sm-6 my-2">
                    </div>
                    <div className="nice-col-xl-3 nice-col-lg-3 nice-col-sm-6 my-2">
                        <a href={iconUrl} ><img className="navbar-box-img" alt='logo' src="/assets/images/ami_logo.jpeg" /></a>
                    </div>
                </div>
            </div>
            <div className="nice-container">
                <div className="nice-row items-center">
                    <div className="nice-col-md-4">
                        <p className="text-white text-2 nice-none">{trans("common:rights")} {new Date().getFullYear()}</p>
                    </div>
                    <div className="nice-col-md-4">
                        <div className="flex footer-owner flex-row  text-white justify-center capitalize">
                           <span className="text-white text-3">{trans("common:power_by")}</span>
                           <img src="/assets/images/visual-logo.png" alt="visual Soft logo"/> 
                        </div>
                    </div>
                    <div className="nice-col-md-4">
                     <a href="https://support.visualsoft.com/account/login" className="text-3 text-white">support.visualsoft.com</a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Footer;
